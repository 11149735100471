<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Nature relation de travail" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de la nature de relation de travail</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">type contrat</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">Code DSN</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le code DSN"
                    dense
                    :error-messages="dsniderror"
                    @keyup="changedsnlabel"
                    @change="changedsnlabel"
                    v-model="dsn.dsnid"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">Libellé DSN</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le libellé DSN"
                    dense
                    v-model="dsn.dsnlabel"
                    @change="changedsnlabel"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import ResourceNotFoundException from "@/exceptions/resource_not_found_exception.js";

import { WorkRelationshipTypesService } from "@/service/user/work_relationship_type_service.js";

import * as stringTools from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditWorkRelationshipType",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,

      /** type contrat */
      label: null,

      /** dsn */
      dsn: { dsnid: "", dsnlabel: "" },
      dsnOrigin: null,
      dsniderror: null,
      dsnmapping: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let entity = await this.service.getById(this.entityId);

        this.dsnmapping = await this.service.getMappingForDsn();

        try {
          this.dsnOrigin = await this.service.getDsnForId(this.entityId);
        } catch (error) {
          if (!(error instanceof ResourceNotFoundException)) {
            throw error;
          }
        }
        this.dsn = this.dsnOrigin
          ? JSON.parse(JSON.stringify(this.dsnOrigin))
          : { dsnid: "", dsnlabel: "" };
        this.source = JSON.parse(JSON.stringify(entity));

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.label = this.source.label;
    },

    async changedsnlabel() {
      if (!stringTools.isNullOrEmpty(this.dsn.dsnid)) {
        const mappingfound = this.dsnmapping.find(
          (map) => map.dsnid === this.dsn.dsnid
        );
        this.dsniderror =
          mappingfound && mappingfound.workrealtiontypeid != this.entityId
            ? "Le code DSN est déja attribué à " +
              mappingfound.workrealtiontypelabel
            : "";
      } else {
        this.dsniderror =
          !stringTools.isNullOrEmpty(this.dsn.dsnid) ||
          (stringTools.isNullOrEmpty(this.dsn.dsnid) &&
            stringTools.isNullOrEmpty(this.dsn.dsnlabel))
            ? ""
            : "Le mapping DSN doit inclure un code";
      }
    },

    async save() {
      // enregistrement
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.label = this.label;

          await this.service.update(entity);
          //Enregistrement des données DSN de mapping

          if (!stringTools.isNullOrEmpty(this.dsn.dsnid)) {
            if (stringTools.isNullOrEmpty(this.dsnOrigin)) {
              await this.service.createDsnForId(entity.id, this.dsn);
            } else {
              await this.service.updateDsnForId(entity.id, this.dsn);
            }
          } else {
            if (!stringTools.isNullOrEmpty(this.dsnOrigin)) {
              await this.service.deleteDsnForId(entity.id);
            }
          }
          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la nature de la relation de travail : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },
  },
  computed: {
    completed() {
      if (!this.label) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifiée */
    entityHasChanged() {
      let changed = false;

      if (!stringTools.areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      //Si pas de dsn en entrée
      if (
        !stringTools.isNullOrEmpty(this.dsn.dsnid) &&
        this.dsnmapping.find((map) => map.dsnid === this.dsn.dsnid)
      ) {
        changed = false;
      } else {
        if (stringTools.isNullOrEmpty(this.dsnOrigin)) {
          if (!stringTools.isNullOrEmpty(this.dsn.dsnid)) changed = true;
        } else {
          if (!stringTools.areTheSame(this.dsnOrigin.dsnid, this.dsn.dsnid)) {
            changed = true;
          }
          if (
            !stringTools.areTheSame(this.dsnOrigin.dsnlabel, this.dsn.dsnlabel)
          ) {
            changed = true;
          }
        }
      }
      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new WorkRelationshipTypesService(
      this.$api.getWorkRelationshipTypeApi()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style></style>
